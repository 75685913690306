import React, { useEffect } from 'react'
import HomeBg from '../assets/Sunita/bgImage-min.png'
import Image1 from '../assets/Sunita/Image1.png'
import Image2 from '../assets/Sunita/Image2.png'
import Image3 from '../assets/Sunita/Image3.png'
import Image4 from '../assets/Sunita/Image4.png'
import bgAim from '../assets/Sunita/bgAim.png'
import Aim1 from '../assets/Sunita/Aim1.png'
import Aim2 from '../assets/Sunita/Aim2.png';
import Aim3 from '../assets/Sunita/Aim3.png';
import AimIcon from '../assets/Sunita/AimIcon.png';
import MenuIcon from '../assets/Sunita/MenuIcon.png';
import LocIcon from '../assets/Sunita/LocIcon.png';
import SponIcon from '../assets/Sunita/SponIcon.png';
import CalIcon from '../assets/Sunita/CalIcon.png';
import chrome from '../assets/Sunita/chrome.png';
import firefox from '../assets/Sunita/firefox.png';
import edge from '../assets/Sunita/edge.png';
import brave from '../assets/Sunita/brave.png';
import opera from '../assets/Sunita/opera.png';
import bgFlower from '../assets/Sunita/bgFlower.png';
import spons from '../assets/Sunita/spons.jpg';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MdOutlineArrowOutward } from "react-icons/md";

const Sunita = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-white min-h-screen scroll-smooth">

            {/* DESKTOP LANDING TILE */}
            <div className="hidden md:flex w-full h-50vh bg-cover bg-center flex justify-center items-center relative">
                {/* BG Image */}
                <div style={{ overflow: 'hidden' }} className="homeBg w-full h-auto">
                    <LazyLoadImage
                        src={HomeBg}
                        effect="blur"
                        width={'100vw'}
                        // height={'90vh'}
                        placeholderSrc={HomeBg}
                        alt="Background Image"
                        className="w-full h-auto object-cover" />
                </div>

                {/* Image Left Text */}
                <div className="p-6 rounded-md absolute w-1/2 text-left left-5 top-12 lg:top-[15vw] xl:top-[20vw] flex flex-col">
                    <h1 className="text-[3.4vw] font-bold mb-4 text-[#FFFFFF]">Program <span className='text-[#FFD100]'>SUNITA</span> <br />Cervical Cancer Mukt Bharat</h1>
                </div>

                {/* Icons */}
                <div className="absolute left-10 top-[35vw] w-1/2 lg:w-[30vw] xl:w-[30vw] flex flex-col justify-end h-auto items-start gap-5 z-10">
                    <div onClick={() => { navigate("/contactus") }} className="bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-[25vw] lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Become a Partner <MdOutlineArrowOutward />
                    </div>
                    {/* <div className="bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-[25vw] lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer">
                        Donate for the Effort <MdOutlineArrowOutward />
                    </div> */}
                </div>
            </div>

            {/* MOBILE LANDING TILE */}
            <div className="block md:hidden w-full h-40vh bg-cover bg-center flex flex-col justify-center items-center">
                {/* BG Image */}
                <div className="homeBg w-full">
                    <img src={HomeBg} alt="Home background" className="w-full h-auto object-cover" />
                </div>

                {/* Image Text */}
                <div className="px-[10vw] w-full text-center flex flex-col justify-center items-center">
                    <h1 className="text-4xl leading-[6vh] font-bold mb-4 text-[#3CA5DC] mt-5">Program <span className='text-[#FFD100]'>SUNITA</span> <br />Cervical Cancer Mukt Bharat</h1>
                </div>

                {/* Icons */}
                <div className="w-[80vw] mx-[10vw] flex flex-col justify-center h-auto items-center gap-5 z-10 cursor-pointer">
                    <div onClick={() => { navigate("/contactus") }} className="mt-5 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-[60vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Become a Partner <MdOutlineArrowOutward />
                    </div>
                    {/* <div className="bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-[60vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2">
                        Donate for the Effort <MdOutlineArrowOutward />
                    </div> */}
                </div>
            </div>

            {/* Desktop Content */}
            <div className="hidden md:flex w-[80vw] mx-[10vw] flex-row justify-center items-center gap-10 my-[8vh]">
                {/* Content */}
                <div className="w-1/2 flex flex-col justify-center items-start">
                    <h1 className="text-xl font-semibold text-[#000000] text-left mb-4">Program SUNITA - Cervical Cancer Mukt Bharat</h1>
                    <h1 className="text-md font-regular text-[#000000] text-left">Program SUNITA (Cervical Cancer Mukt Bharat) aims to revolutionize cervical cancer prevention and control in India by implementing a comprehensive approach. Through widespread HPV testing, vaccination campaigns, and digital connectivity initiatives, the program seeks to detect cases early, protect individuals through vaccination, and connect millions for remote patient monitoring. By leveraging innovative strategies and partnerships, Program SUNITA aspires to create a future where cervical cancer is no longer a public health threat in India.</h1>
                    <div onClick={() => { navigate("/contactus") }} className="mt-5 bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-[25vw] lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Sponsor Now <MdOutlineArrowOutward />
                    </div>

                    <h1 className="text-xl font-semibold text-[#000000] text-left mt-[8vh] mb-4">What is Cervical Cancer and How it affects Women ?</h1>
                    <h1 className="text-md font-regular text-[#000000] text-left">Cervical cancer, which develops in a woman{"\'"}s cervix, is the second-most common cancer among women in India. It is caused by persistent infection by the human papillomavirus (HPV). India accounts for nearly a quarter of all cervical cancer deaths in the world.</h1>
                    <a href='https://www.who.int/news-room/fact-sheets/detail/cervical-cancer?gad_source=1&gclid=CjwKCAjwt-OwBhBnEiwAgwzrUiNHKXDduRb0VVH9KQ3-aYL_5fldsv4EMa57okddkMKqxWE9ZbUKVRoC4TYQAvD_BwE' target='_blank' className="mt-5 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-[25vw] lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Learn More <MdOutlineArrowOutward />
                    </a>
                </div>

                {/* Images */}
                <div className="w-1/2 flex flex-row justify-center items-center gap-2">
                    <div className="flex flex-col justify-center items-center gap-2">
                        <div className="w-full flex justify-center items-center">
                            <img src={Image1} alt="" className="w-[20vw] lg:w-[15vw] h-auto object-contain" />
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <img src={Image2} alt="" className="w-[20vw] lg:w-[15vw] h-auto object-contain" />
                        </div>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-2 mt-[10vh]">
                        <div className="w-full flex justify-center items-center">
                            <img src={Image3} alt="" className="w-[20vw] lg:w-[15vw] h-auto object-contain" />
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <img src={Image4} alt="" className="w-[20vw] lg:w-[15vw] h-auto object-contain" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile Content */}
            <div className="block md:hidden w-[80vw] mx-[10vw] flex flex-col-reverse justify-center items-center gap-10">
                {/* Content */}
                <div className="w-full flex flex-col justify-center items-start">
                    <h1 className="text-xl font-semibold text-[#000000] text-left mb-4">Program SUNITA - Cervical Cancer Mukt Bharat</h1>
                    <h1 className="text-md font-regular text-[#000000] text-left">Program SUNITA (Cervical Cancer Mukt Bharat) aims to revolutionize cervical cancer prevention and control in India by implementing a comprehensive approach. Through widespread HPV testing, vaccination campaigns, and digital connectivity initiatives, the program seeks to detect cases early, protect individuals through vaccination, and connect millions for remote patient monitoring. By leveraging innovative strategies and partnerships, Program SUNITA aspires to create a future where cervical cancer is no longer a public health threat in India.</h1>
                    <div onClick={() => { navigate("/contactus") }} className="mt-5 bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-1/2 px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Sponsor Now <MdOutlineArrowOutward />
                    </div>

                    <h1 className="text-xl font-semibold text-[#000000] text-left mt-[8vh] mb-4">What is Cervical Cancer and How it affects Women?</h1>
                    <h1 className="text-md font-regular text-[#000000] text-left">Cervical cancer, which develops in a woman{"\'"}s cervix, is the second-most common cancer among women in India. It is caused by persistent infection by the human papillomavirus (HPV). India accounts for nearly a quarter of all cervical cancer deaths in the world.</h1>
                    <a href='https://www.who.int/news-room/fact-sheets/detail/cervical-cancer?gad_source=1&gclid=CjwKCAjwt-OwBhBnEiwAgwzrUiNHKXDduRb0VVH9KQ3-aYL_5fldsv4EMa57okddkMKqxWE9ZbUKVRoC4TYQAvD_BwE' target='_blank' className="mt-5 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-1/2 px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Learn More <MdOutlineArrowOutward />
                    </a>
                </div>

                {/* Images */}
                <div className="w-full flex flex-row justify-center items-center gap-2">
                    <div className="flex flex-col justify-center items-center gap-2">
                        <div className="w-full flex justify-center items-center">
                            <img src={Image1} alt="" className="w-[40vw] h-auto object-contain" />
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <img src={Image2} alt="" className="w-[40vw] h-auto object-contain" />
                        </div>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-2 mt-[10vh]">
                        <div className="w-full flex justify-center items-center">
                            <img src={Image3} alt="" className="w-[40vw] h-auto object-contain" />
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <img src={Image4} alt="" className="w-[40vw] h-auto object-contain" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-[10vh] w-full min-h-screen flex flex-col justify-center items-center relative">
                {/* Background Image */}
                <div className="w-full flex justify-center items-center">
                    <img src={bgAim} alt="background" className="w-full h-[250vh] md:h-[220vh] lg:h-[140vh] object-cover" />
                </div>
                <div className="absolute top-0 right-0 w-[40vw] lg:w-[35vw] xl:w-[28vw] flex justify-center items-center">
                    <img src={bgFlower} alt="Flower" className="w-full h-full object-cover" />
                </div>

                {/* Content */}
                <div className="pt-10 w-[80vw] mx-[10vw] flex flex-col absolute top-10 justify-center items-center">

                    <div className="w-full flex flex-row justify-center items-center text-center gap-10">
                        <h1 className="text-4xl font-regular text-[#000000] text-center">Important Details</h1>
                        <div className="flex justify-center items-center">
                            <img src={MenuIcon} alt="Icon" className="w-7 h-auto object-contain" />
                        </div>
                    </div>

                    <div className="w-full mt-[10vh] flex flex-row justify-start items-start gap-4">
                        <div className="flex justify-center items-center">
                            <img src={LocIcon} alt="Icon" className="w-7 h-auto object-contain" />
                        </div>
                        <h1 className="text-lg font-bold text-[#000000] text-left">WHERE WE START?</h1>
                    </div>
                    <h1 className="w-full mt-2 ml-[6vw] text-lg font-regular text-[#000000] text-left">Rajasthan as Pilot, followed by over 100 cities and <br />villages across India.</h1>

                    <div className="w-full mt-[5vh] flex flex-row justify-start items-start gap-4">
                        <div className="flex justify-center items-center">
                            <img src={CalIcon} alt="Icon" className="w-7 h-auto object-contain" />
                        </div>
                        <h1 className="text-lg font-bold text-[#000000] text-left">WHEN WE START?</h1>
                    </div>
                    <h1 className="w-full mt-2 ml-[6vw] text-lg font-regular text-[#000000] text-left">September 2024 onwards</h1>

                    <div className="w-full mt-4 flex justify-start items-start">
                        <div onClick={() => { navigate("/contactus") }} className="mt-5 bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-start w-full sm:w-1/2 lg:w-1/4 px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                            Sponsor Now <MdOutlineArrowOutward />
                        </div>
                    </div>

                    <div className="mt-10 flex flex-row justify-center items-center gap-2">
                        <h1 className="text-4xl font-regular text-[#000000] text-left">Our Aim</h1>
                        <div className="flex justify-center items-center">
                            <img src={AimIcon} alt="Icon" className="w-16 h-auto object-contain" />
                        </div>
                    </div>

                    {/* Cards */}
                    <div className="w-full mt-10 flex flex-col lg:flex-row justify-center items-center gap-10">
                        <div style={{ borderTopLeftRadius: '50%', borderTopRightRadius: '50%' }} className='bg-[#3CA5DC] w-[70vw] md:w-[35vw] pb-10 flex flex-col justify-center items-center rounded-3xl'>
                            <div className="w-full h-auto flex justify-center items-center relative">
                                <img src={Aim3} alt="Aim" className="w-full h-auto object-cover" />
                                <div className="w-full mt-4 flex justify-start items-start">
                                    <div className="absolute top-5 left-5 bg-[#EEC300] flex justify-center font-bold text-[#FFFFFF] text-sm items-start w-1/3 px-1 py-1 rounded-xl shadow-xl border-2 border-white">
                                        Detect
                                    </div>
                                </div>
                            </div>
                            <p className='mt-5 text-4xl px-4 text-center font-bold text-[#284356]'>10 Million</p>
                            <p
                                className={`mt-2 text-lg px-2 text-center font-bold text-[#FFFFFF]`}>HPV Tests Completion
                            </p>
                        </div>
                        <div style={{ borderTopLeftRadius: '50%', borderTopRightRadius: '50%' }} className='bg-[#3CA5DC] w-[70vw] md:w-[35vw] pb-10 flex flex-col justify-center items-center rounded-3xl'>
                            <div className="relative w-full h-auto flex justify-center items-center">
                                <img src={Aim1} alt="Aim" className="w-full h-auto object-cover" />
                                <div className="w-full mt-4 flex justify-start items-start">
                                    <div className="absolute top-5 left-5 bg-[#EEC300] flex justify-center font-bold text-[#FFFFFF] text-sm items-start w-1/3 px-1 py-1 rounded-xl shadow-xl border-2 border-white">
                                        Protect
                                    </div>
                                </div>
                            </div>
                            <p className='mt-5 text-4xl px-4 text-center font-bold text-[#284356]'>1 Million</p>
                            <p
                                className={`mt-2 text-lg px-2 text-center font-bold text-[#FFFFFF]`}>Vaccinations Goal
                            </p>
                        </div>
                        <div style={{ borderTopLeftRadius: '50%', borderTopRightRadius: '50%' }} className='bg-[#3CA5DC] w-[70vw] md:w-[35vw] pb-10 flex flex-col justify-center items-center rounded-3xl'>
                            <div className="relative w-full h-auto flex justify-center items-center">
                                <img src={Aim2} alt="Aim" className="w-full h-full object-cover" />
                                <div className="w-full mt-4 flex justify-start items-start">
                                    <div className="absolute top-5 left-5 bg-[#EEC300] flex justify-center font-bold text-[#FFFFFF] text-sm items-start w-1/3 px-1 py-1 rounded-xl shadow-xl border-2 border-white">
                                        Connect
                                    </div>
                                </div>
                            </div>
                            <p className='mt-5 text-4xl px-4 text-center font-bold text-[#284356]'>10 Million</p>
                            <p
                                className={`mt-2 text-lg px-2 text-center font-bold text-[#FFFFFF]`}>Remote Patients Monitoring
                            </p>
                        </div>
                        {/* <div style={{ borderTopLeftRadius: '50%', borderTopRightRadius: '50%' }} className='bg-[#3CA5DC] w-[70vw] md:w-[38vw] pb-10 flex flex-col justify-center items-center rounded-3xl'>
                            <div className="w-full h-auto flex justify-center items-center">
                                <img src={Aim2} alt="Aim" className="w-full h-auto object-cover" />
                            </div>
                            <p className='mt-5 text-4xl px-4 text-center font-bold text-[#284356]'>10 Million</p>
                            <p
                                className={`mt-2 text-xl px-4 text-center font-bold text-[#FFFFFF]`}>
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="w-[80vw] mx-[10vw] mt-[10vh] flex flex-col justify-center items-center">
                <div className="flex flex-row justify-center items-center gap-10">
                    <h1 className="text-4xl font-regular text-[#000000] text-left">Our Partners & Sponsors</h1>
                    <div className="flex justify-center items-center">
                        <img src={SponIcon} alt="Icon" className="w-16 lg:w-10 h-auto object-contain" />
                    </div>
                </div>
                <div className="my-[10vh] flex flex-col justify-center items-center">
                    <div className="flex justify-center items-center">
                        <img src={spons} alt="Sponsor" className="w-64 h-auto object-contain" />
                    </div>
                    <h1 className="mt-10 text-lg font-regular text-[#000000] text-center">And many more to come, You can also be a part and Join Us in this Mission.</h1>
                    <div onClick={() => { navigate("/contactus") }} className="mt-5 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-1/2 px-2 py-4 rounded-l-full rounded-r-full shadow-2xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Become A Partner <MdOutlineArrowOutward />
                    </div>
                </div>
            </div>

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    )
}

export default Sunita