const environment = "DEV";
// const environment = "PROD";
// const environment = "STAGE";

let DEFAULT_APP_URL;

switch (environment) {
  case "PROD":
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_PROD;
    break;
  case "STAGE":
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_STAGE;
    break;
  default:
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_DEV;
}

const Constant = {
  DEFAULT_APP_URL,
};

export default Constant;
